<template>
  <div style="width:100%; height:100%; position:relative;z-index:1;">
    <video ref="video" @canplay="initCanvas()" @ended="endedCamera()">
      Strem unavailable
    </video>
    <canvas ref="canvas" style="display:none;" />
    <div
      class="d-flex justify-center align-center"
      style="position:absolute; bottom:5px; right:0; left:0; z-index:99;"
    >
      <v-btn
        id="starCamera"
        icon
        color="black"
        text
        @click="startStream"
        style="margin-right:20px; display:none;"
      >
        <v-icon large color="black">mdi-play</v-icon>
      </v-btn>
      <v-btn icon outlined x-large color="black" text @click="takePicture">
        <v-icon x-large color="black">mdi-camera</v-icon>
      </v-btn>
      <v-btn
        id="stopCamera"
        icon
        color="black"
        text
        @click="stopStream"
        style="margin-left:20px; display:none;"
      >
        <v-icon large color="black">mdi-pause</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VueCamera',
  data: () => ({
    video: null,
    canvas: null
  }),
  methods: {
    stopStream() {
      console.log('stop camera')
      this.video.srcObject.getTracks().forEach(function(track) {
        track.stop()
      })
      this.canvas = null
      this.video = null
    },
    startStream() {
      console.log('open camera')
      //   this.video.play()
      this.canvas = this.$refs.canvas
      this.video = this.$refs.video
      this.startCapture()
    },
    startCapture() {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then(stream => {
          this.video.srcObject = stream
          this.video.play()
        })
        .catch(error => {
          console.log(error)
        })
    },
    initCanvas() {
      this.canvas.setAttribute('width', this.video.videoWidth)
      this.canvas.setAttribute('height', this.video.videoHeight)
    },
    endedCamera() {
      console.log('camera end')
    },
    takePicture() {
      const context = this.canvas.getContext('2d')
      context.drawImage(
        this.video,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight
      )
      //   this.$emit('picture-taken', this.canvas.toDataURL('image/png'))

      this.canvas.toBlob(blob => {
        var file = new File([blob], 'my-pict.png')
        this.stopStream()
        setTimeout(() => {
          this.$emit('picture-taken', file)
        }, 300)
        // const newImg = document.createElement('img')
        // console.log(newImg)
        // const url = URL.createObjectURL(blob)
        // console.log(url)

        // newImg.onload = () => {
        //   // no longer need to read the blob so it's revoked
        //   URL.revokeObjectURL(url)
        // }

        // newImg.src = url
        // console.log(newImg)
        // document.body.appendChild(newImg)
      })
    }
  }
}
</script>
<style scoped>
video {
  /* position: absolute;
  transform: rotate(90deg);

  transform-origin: bottom left;
  width: 100%;
  height: 100%;
  margin-top: -100vw;
  object-fit: cover;

  visibility: visible; */
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>
